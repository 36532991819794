import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

class EventDialog extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      open: props.open,
      handleClose: props.handleClose,
      event: props.event
    };
  }

  formatDate(date) {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    }).format(new Date(date));
  }

  render() {
    const { open, handleClose, event } = this.props;

    return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, mr: 6, p: 2 }} id="customized-dialog-title">
          {event.title}
        </DialogTitle>
        <Button
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </Button>
        <DialogActions>
        {event.extendedProps.link &&
              <Button href={event.extendedProps.link} variant='outlined' color='primary' size='small'>{event.extendedProps.link}</Button>
            }
          <AddToCalendarButton
          size="2"
          lightMode="bodyScheme"
            listStyle="modal"
            name={event.title}
            startDate={new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(event.start)).split('/').reverse().join('-')}
            {...(event.end && {
              endDate: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(event.end)).split('/').reverse().join('-')
            })}
            startTime={new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(new Date(event.start))}
            {...(event.end && {
              endTime: new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(new Date(event.end))
            })}
            {...(!event.end && {
              endTime: new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(new Date(event.start))
            })}
            options={['MicrosoftTeams', 'Microsoft365', 'Apple', 'Google', 'iCal']
          }
          ></AddToCalendarButton>
        </DialogActions>
        <DialogContent>
          <Typography gutterBottom>
            start: {this.formatDate(event.start)}<br />
            end: {this.formatDate(event.end)}<br />
            {event.extendedProps.location &&
              <>location: {event.extendedProps.location}<br /></>
            }
            {event.extendedProps.contact &&
              <>contact: {event.extendedProps.contact}<br /></>
            }
            {event.extendedProps.workinggroup &&
              <>working group: {event.extendedProps.workinggroup}<br /></>
            }
            <>themes: {event.classNames.join(", ")}<br /></>


          </Typography>
          {/* ... autres éléments de contenu */}
        </DialogContent>
      </Dialog>
    );
  }
}
export default EventDialog;