import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';

class EventTooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open,
            handleClose: props.handleClose,
            event: props.event,
            anchorEl: props.anchorEl
        };
        //console.log(props.anchorEl)
    }

    formatDate(date) {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false
        }).format(new Date(date));
    }

    render() {
        const { open, handleClose, event, anchorEl } = this.props;
        if (!event) {
            return null; // Ne rend rien si l'événement est indéfini
        }
        else {
            return (
                <Tooltip open={open} onClose={handleClose} title={
                    <React.Fragment>
                        <><strong>{event.title}</strong><br /><br /></>
                        <>start: {this.formatDate(event.start)}<br /></>
                        {event.end &&
                            <>end: {this.formatDate(event.end)}<br /></>
                            } 
                        {event.extendedProps.location && 
                            <>location: {event.extendedProps.location}<br /></>
                        }
                        <>contact: {event.extendedProps.contact}<br /></> 
                        <>working group: {event.extendedProps.workinggroup}<br /></>
                        <>themes: {event.classNames.join(", ")}<br /></>
                        {event.extendedProps.uicregion && 
                            <>region: {event.extendedProps.uicregion}</>
                        }
                        

                    </React.Fragment>
                } arrow transition='Zoom'
                    PopperProps={{
                        anchorEl: anchorEl
                    }}>
                    <span className='refspan'></span>
                </Tooltip>
            );
        }
    }
}
export default EventTooltip;