import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export const FilterToggleMenu = ({ tooglecategories, toogleclick, togglesstates }) => {

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, xl: 1 }}
        >
            {tooglecategories.map((type) => (

                <ToggleButton
                    key={type.classname}
                    value={type.name}
                    title={"Filter on " + type.name}
                    aria-label={type.name}
                    data-type={type.classname}
                    className={type.classname}
                    selected={togglesstates[type.classname]}
                    onClick={() => toogleclick(type.classname)}
                    sx={{ px: 1, border: 0, textAlign: 'left', justifyContent: 'left', textTransform: 'none' }}
                >
                    {type.classname === 'all' ? <FilterAltOffIcon /> : <FilterAltIcon />}
                    {type.name}
                </ToggleButton>
            ))}
        </Stack>
    )
}