import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import LinearProgress from '@mui/material/LinearProgress';
import EventDialog from './component/EventDialog'
import EventTooltip from './component/EventTooltip'
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import TuneIcon from '@mui/icons-material/Tune';
import { FilterToggleMenu } from './component/FilterToggleMenu'
import './App.css';

const myData = [];

const eventCategories = [
  { name: 'All', classname: 'all' },
  { name: 'Statutory', classname: 'statutory' },
  { name: 'Work meeting', classname: 'work' },
  { name: 'Training', classname: 'training' },
  { name: 'Major event', classname: 'major' },
  { name: 'Workshop & Seminar', classname: 'workshop' }
];

const searchParams = new URLSearchParams(window.location.search);
const print = () => window.print();

const typeParam = searchParams.get('type');
const modeParam = searchParams.get('mode');
const uicregionParam = searchParams.get('uicregion');

const initialCatFilters = {
  statutory: false,
  work: false,
  training: false,
  major: false,
  workshop: false
};

const App = () => {

  const [progress, setProgress] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [initialView, setInitialView] = useState('dayGridMonth');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setTooltipOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [catfilters, setFilters] = useState(initialCatFilters);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [togglesStates, setTogglesStates] = useState(
    eventCategories.reduce((acc, type) => {
      acc[type.classname] = type.classname === 'all';
      return acc;
    }, {})
  );

  const handleClickOpen = (info) => {
    setSelectedEvent(info.event);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = (info) => {
    if (!isTouchDevice && info.view.type === 'dayGridMonth') {
      setSelectedEvent(info.event);
      setAnchorEl(info.el);
      setTooltipOpen(true);
    }
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.view.type === 'listWeek' || eventInfo.view.type === 'listDay' || eventInfo.view.type === 'listMonth' || eventInfo.view.type === 'listYear') {
      return (
        <div className='description'>
          <strong>{eventInfo.event.title}</strong>
          <p>
            <small>
              {eventInfo.event.extendedProps.location &&
                <> Location: <strong>{eventInfo.event.extendedProps.location}</strong> |</>
              }
              Contact: <strong>{eventInfo.event.extendedProps.contact}</strong> |
              Working group: <strong>{eventInfo.event.extendedProps.workinggroup}</strong> |
              Themes: <strong>{eventInfo.event.classNames.join(", ")}</strong>
              {eventInfo.event.extendedProps.uicregion &&
                <> | Region: <strong>{eventInfo.event.extendedProps.uicregion}</strong></>
              }
            </small>
          </p>
        </div>
      );
    } else {
      return true;
    }
  };

  const [events, setEvents] = useState(
    myData.map(event => ({
      ...event
    }))
  );

  const handleFilterClick = (type) => {

    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: !prevFilters[type],
    }));

    setTogglesStates({
      ...togglesStates,
      [type]: !togglesStates[type],
      ...(type !== 'all' && { all: false })
    });
    if (type === 'all') {
      resetFilters()
    }

  };

  const resetFilters = () => {
    setFilters(initialCatFilters);
    setTogglesStates(
      Object.keys(togglesStates).reduce((acc, key) => {
        acc[key] = key === 'all';
        return acc;
      }, {})
    );
  };

  const clearSearch = () => {
    setSearchInput('');
  };

  const getFilteredEvents = useCallback(() => {
    const isAnyFilterActive = Object.values(catfilters).some(value => value);

    return events.filter(event => {
      const matchesFilters = isAnyFilterActive ? Object.keys(catfilters).some(filterType => {
        return catfilters[filterType] && event.classNames.includes(filterType);
      }) : true;

      const matchesSearch = event.title.toLowerCase().includes(searchInput.toLowerCase());
      const matchesRegion = event.uicregion === uicregionParam;
      const matchesKeyword = (typeParam && event.classNames.includes(typeParam));

      return matchesFilters && (searchInput === "" || matchesSearch) && (!typeParam || matchesKeyword) && (!uicregionParam || matchesRegion);
    });
  }, [events, catfilters, searchInput]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setInitialView((windowWidth > 600 && modeParam !== 'embed') ? 'dayGridMonth' : 'listMonth');
    const checkTouchScreen = () => {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };

    setIsTouchDevice(checkTouchScreen());

    const fetchEvents = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_PATH);
        if (!response.ok) {
          throw new Error('Get events failed');
        }
        const data = await response.json();
        setEvents(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Get events failed', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvents();

    return () => window.removeEventListener('resize', handleResize);

  }, [windowWidth]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };

  }, []);

  if (isLoading) {
    return (<Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>);
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        contentHeight='auto'
        contentWidth='auto'
        initialView={initialView}
        weekends={false}
        headerToolbar={{
          start: 'dayGridMonth,listMonth,listYear',
          center: 'title',
          end: 'today,prev,next',
        }}
        buttonText={{
          today: 'today',
          dayGridMonth: 'Month Grid',
          timeGridWeek: 'Week Grid',
          listMonth: 'Month List',
          listYear: 'Year list'
        }}
        eventContent={renderEventContent}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
        }}
        locale='en-GB'
        eventDisplay='block'
        eventBorderColor='#ffffff'
        eventMouseEnter={handleTooltipOpen}
        eventMouseLeave={handleTooltipClose}
        eventClick={handleClickOpen}
        weekNumbers={true}
        weekNumberCalculation='ISO'
        scrollTime={0}
        events={getFilteredEvents()}
      />

      {selectedEvent && (
        <EventDialog
          open={dialogOpen}
          handleClose={handleClose}
          event={selectedEvent}
        />
      )}

      {selectedEvent && (
        <EventTooltip
          open={openTooltip}
          handleClose={handleClose}
          event={selectedEvent}
          anchorEl={anchorEl}
        />
      )}

      {modeParam !== 'embed' && (
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'white' }} component="nav">

          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <FormControl sx={{ mr: 2 }}>
                <InputLabel htmlFor="filtertitle">Filter by Title</InputLabel>
                <FilledInput
                  id="filtertitle"
                  label="filter by title"
                  variant="outlined"
                  size='small'
                  sx={{ backgroundColor: 'transparent' }}
                  aria-label="filter by title"
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mx: 0 }}>
                      <Button title='clear filter' onClick={clearSearch} value='clearsearch' key='clearsearch' size="large" disabled={(searchInput ? false : true)} sx={{ mt: 1, mx: 0 }}>
                        <CloseIcon />
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {windowWidth <= 1080 && (

                <>
                  <Button
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onMouseEnter={handleOpenNavMenu}
                    color="primary"
                    sx={{ textTransform: 'none' }}
                  >
                    <TuneIcon />
                    Filter by Category
                  </Button>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >

                    <FilterToggleMenu
                      tooglecategories={eventCategories}
                      toogleclick={handleFilterClick}
                      togglesstates={togglesStates}
                    />

                  </Menu>
                </>
              )}

              {windowWidth > 1080 && (

                <FilterToggleMenu
                  tooglecategories={eventCategories}
                  toogleclick={handleFilterClick}
                  togglesstates={togglesStates}
                />


              )}
            </Box>

            <Button title='Print' onClick={print} value='print' key='print' size="small" variant='text' color='info'>
              <PrintIcon sx={{ color: '#999999' }} />
            </Button>
          </Toolbar>

        </AppBar>

      )}
    </>
  );
};

export default App;